<template>
	<Drawer ref="ovsg-drawer" :title="$t('Select goals')" :initialWidth="showSuggestions? '3xl':'5xl'" :closeOnClickOutside="true" @requestUnmount="onDrawerRequestsClose">

		<!-- <template #default="{ hide }"> -->
		<template v-if="showSuggestions" #default="{ resizeTo }">
			<div class="p-4">
			<p class="font-bold mb-4">{{ $t('Suggestions') }}</p>
			<ul class="mb-4">
				<li v-for="goal in suggestedGoals" :key="goal.id" :class="goal.isSubgoal() ? 'pl-4' : 'pl-0'">
					<GoalListItem
						:listItem="goal"
						action="select"
						:checked="selectedSuggestions.has(goal.sourceId)"
						@checked="selectSuggestedGoal"
						@unchecked="deselectSuggestedGoal"
					/>
				</li>
			</ul>
			<div class="flex justify-between gap-4 px-2 pt-2">
				<button type="button" class="btn btn-neutral btn-outline" @click="() => { prepareOvsgSelector(); resizeTo('5xl'); }">Open OVSG Selector ...</button>
				<button type="button" class="btn btn-primary" @click="closeSuggestionsPanel()">Klaar</button>
			</div>
			</div>
		</template>
		<template v-else #default>
			<div>
				<div v-if="showClientError" class="alert alert-error text-sm">
					Door een storing kon de doel-info momenteel niet worden opgehaald van het OVSG-platform. Probeer nogmaals opnieuw, of meld ons het probleem
					(via de knop helemaal onderaan het hoofdmenu) wanneer het zich blijft voordoen.
				</div>
				<div v-else class="alert alert-info">Selecteer de doelen en/of ontwikkelstappen, kijk je selectie na, en kies dan 'Zet selectie in {{ buttonLabelSuffix }}'.</div>
			</div>

			<div ref="selector-container" class="w-full h-full relative">
				<div v-if="retrievingGoalData" class="absolute w-full h-full bg-white/50 flex items-center justify-center">
					<span class="loading loading-spinner loading-lg"></span>
				</div>
				<iframe ref="ovsg-selector" src="" style="width: 100%; height: 100%; border: none;"></iframe>
			</div>
		</template>
		<!-- </template> -->

	</Drawer>
</template>



<script lang="ts">
import { mapStores, mapState } from "pinia";
import { useOrganisationsStore } from "@/stores/Organisations.store";

import Drawer from '@/components/ui/Drawer.v2.vue';
import GoalListItem from '@/components/GoalListItem.vue';
import { OVSG_SELECTOR_DOMAIN, OVSG_SELECTOR_PATH } from "@/goals/ovsg/settings";
import {OvsgLoader} from "@/goals/ovsg/utils/OvsgLoader.util";
import {Goal} from "@/models/Goal.model";

export default {

	emits: [
		'requestUnmount',
		'goalSelected',
		'goalUnselected'
	],

	props: {
		selectedGoals: {
			type: Array,
			default: () => [],
		},

		suggestedGoals: {
			type: Array,
			default: () => [],
		},

		context: {
			type: String,
		},
	},

	components: {
		Drawer,
		GoalListItem,
	},

	data() {
		return {
			showSuggestions: false,
			selectedSuggestions: new Map(),
			retrievingGoalData: false,
			showClientError: false,
		}
	},

	computed: {
		...mapStores(useOrganisationsStore),

		buttonLabelSuffix() {
			if(this.context === 'theme') {
				return 'thema';
			} else {
				return 'lesplan';
			}
		},
	},

	created() {
		this.showSuggestions = this.selectedGoals.length === 0 && this.suggestedGoals.length > 0;
	},

	async mounted() {

		if(!this.showSuggestions) {
			this.prepareOvsgSelector();
		}

		//(new OvsgLoader()).load('384b3499-6136-4abf-b040-dd023419b785');

		//this.handleSelectionUpdate('384b3499-6136-4abf-b040-dd023419b785');
	},

	beforeUnmount() {
		window.removeEventListener("message", this.handlePostMessage);
		window.removeEventListener("resize", this.adjustIframeHeight);
	},

	methods: {
		addGoal(goal) {
			// check if goal was already selected (just in case, normally not possible if OVSG Selector got initialized correctly)
			if(this.selectedGoals.find(g => g.sourceId === goal.sourceId)) {
				return;
			}
			this.$emit('goalSelected', goal);
		},

		removeGoal(goal) {
			this.$emit('goalUnselected', goal);
		},

		selectSuggestedGoal(goal) {
			this.selectedSuggestions.set(goal.sourceId, goal);

			// also add the parent goal if any
			if(goal.sourceParentPath) {
				const parentGoal = this.suggestedGoals.find(g => g.fullPath === goal.sourceParentPath);
				if(parentGoal) {
					this.selectedSuggestions.set(parentGoal.sourceId, parentGoal);
				}
			}
		},

		deselectSuggestedGoal(goal) {
			this.selectedSuggestions.delete(goal.sourceId);

			// also remove selected subgoals if any
			const subgoals = this.suggestedGoals.filter(g => g.sourceParentPath === goal.fullPath);
			for(let subgoal of subgoals) {
				this.selectedSuggestions.delete(subgoal.sourceId);
			}
		},

		prepareOvsgSelector() {

			this.commitSelectedSuggestedGoals();
			this.showSuggestions = false;

			this.$nextTick().then(() => {
				window.addEventListener('message', this.handlePostMessage);
				window.addEventListener("resize", this.adjustIframeHeight);

				/*
				this.ovsgSelectorWindow = this.$refs['ovsg-selector'].contentWindow;
				this.adjustIframeHeight();
				this.loadOvsgSelector();

				 */

				this.loadOvsgSelector();
			});
		},

		handlePostMessage(event) {

			if (event.origin !== OVSG_SELECTOR_DOMAIN || !event.source.opener) {
				console.error('Received postMessage from unexpected origin');
				return;
			}

			const { actie, inhoud } = event.data;

			// Leer Lokaal is klaar en vraagt een lege of bestaande selectie
			if (actie === 'vraag_selectie') {

				const selectedGoals = {
					basisdoel_uuids: [],
					attitude_uuids: [],
				};

				this.selectedGoals.forEach((goal: Goal) => {

					const sourceIdSplit = goal.sourceId.split('/');
					switch (sourceIdSplit.shift()) {
						case 'A':
							selectedGoals.attitude_uuids.push(sourceIdSplit.join('/'));
							break;

						case 'B':
							selectedGoals.basisdoel_uuids.push(sourceIdSplit.join('/'));
							break;
					}

				});

				this.ovsgSelectorWindow.postMessage(
					{
						actie: 'huidige_selectie',
						inhoud: selectedGoals
					},
					OVSG_SELECTOR_DOMAIN
				);
			}

			// ontvangst van een nieuwe selectie van Leer Lokaal
			if (actie === 'nieuwe_selectie') {
				const { selectie_uuid, basisdoel_uuids, attitude_uuids } = inhoud;
				this.handleSelectionUpdate(selectie_uuid);
			}

		},

		loadOvsgSelector() {
			//this.ovsgSelectorWindow.location = OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH;

			//let win = window.open(OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH);
			this.ovsgSelectorWindow = window.open(OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH, 'ovsg-selector', 'width=800,height=600');

			// Listen for popup close.
			var timer = setInterval(() => {
				if(!this.ovsgSelectorWindow || this.ovsgSelectorWindow.closed) {
					clearInterval(timer);
					this.closeDrawer();
				}
			}, 500);

			//this.ovsgSelectorWindow.location = OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH;
			//this.ovsgSelectorWindow.opener = window;
		},

		adjustIframeHeight() {
			let container = this.$refs['selector-container'];
			let iframe = this.$refs['ovsg-selector'];
			iframe.style.height = container.clientHeight + "px";
		},

		async handleSelectionUpdate(selectionUid: string) {
			this.retrievingGoalData = true;

			const goalsToRemove = new Map(this.selectedGoals.map(goal => [goal.sourceId, goal]));

			let loader = new OvsgLoader();
			try {
				await loader.load(selectionUid).then(goals => {
						goals.forEach((goal) => { this.addGoal(goal); goalsToRemove.delete(goal.sourceId); });
				});
			} catch(e) {
				console.error('Error loading goals from OVSG API', e);
				this.retrievingGoalData = false;
				this.showClientError = true;
				return;
			}

			// remove goals that are no longer selected
			for(let goal of goalsToRemove.values()) {
				this.removeGoal(goal);
			}

			this.closeDrawer();
		},

		closeDrawer() {
			if (this.$refs['ovsg-drawer']) {
				this.$refs['ovsg-drawer'].prepareToClose();
			}
		},

		onDrawerRequestsClose() {
			if (this.ovsgSelectorWindow) {
				this.ovsgSelectorWindow.close();
				this.ovsgSelectorWindow = null;
			}

			this.$emit('requestUnmount');
		},

		commitSelectedSuggestedGoals() {

			const selectedSuggestions = this.selectedSuggestions.values();
			for(let goal of selectedSuggestions) {
				this.addGoal(goal);
			}

		},

		closeSuggestionsPanel() {
			this.showSuggestions = false;
			this.commitSelectedSuggestedGoals();

			this.$refs['ovsg-drawer'].prepareToClose();
		},
	}

}
</script>
