<template>
	<Drawer ref="zill-drawer" :title="$t('Select goals')" :initialWidth="showSuggestions? '3xl':'5xl'" :closeOnClickOutside="true" @requestUnmount="$emit('requestUnmount')">

		<!-- <template #default="{ hide }"> -->
		<template v-if="showSuggestions" #default="{ resizeTo }">
			<div class="p-4">
			<p class="font-bold mb-4">{{ $t('Suggestions') }}</p>
			<ul class="mb-4">
				<li v-for="goal in suggestedGoals" :key="goal.id" :class="goal.isSubgoal() ? 'pl-4' : 'pl-0'">
					<GoalListItem
						:listItem="goal"
						action="select"
						:checked="selectedSuggestions.has(goal.sourceId)"
						@checked="selectSuggestedGoal"
						@unchecked="deselectSuggestedGoal"
					/>
				</li>
			</ul>
			<div class="flex justify-between gap-4 px-2 pt-2">
				<button type="button" class="btn btn-neutral btn-outline" @click="() => { prepareZillSelector(); resizeTo('5xl'); }">Open ZiLL Selector ...</button>
				<button type="button" class="btn btn-primary" @click="closeSuggestionsPanel()">Klaar</button>
			</div>
			</div>
		</template>
		<template v-else #default>
			<div>
				<div v-if="showZillError" class="alert alert-error text-sm">
					Door een storing kon de doel-info momenteel niet worden opgehaald van het ZiLL-platform. Probeer nogmaals opnieuw, of meld ons het probleem
					(via de knop helemaal onderaan het hoofdmenu) wanneer het zich blijft voordoen.
				</div>
				<div v-else class="alert alert-info">Selecteer de doelen en/of ontwikkelstappen, kijk je selectie na, en kies dan 'Zet selectie in {{ buttonLabelSuffix }}'.</div>
			</div>

			<div id="selector-container" class="w-full h-full relative">
				<div v-if="retrievingGoalData" class="absolute w-full h-full bg-white/50 flex items-center justify-center">
					<span class="loading loading-spinner loading-lg"></span>
				</div>
				<iframe id="zill-selector" src="" style="width: 100%; height: 100%; border: none;"></iframe>
			</div>
		</template>
		<!-- </template> -->

	</Drawer>
</template>



<script lang="ts">
import { mapStores, mapState } from "pinia";
import { useOrganisationsStore } from "@/stores/Organisations.store";

import Drawer from '@/components/ui/Drawer.v2.vue';
import GoalListItem from '@/components/GoalListItem.vue';
import { ZillLoader } from "@/goals/zill/utils/ZillLoader.util";
import { ZILL_SELECTOR_URL } from "@/goals/zill/settings";



export default {

	emits: [
		'requestUnmount',
		'goalSelected',
		'goalUnselected'
	],

	props: {
		selectedGoals: {
			type: Array,
			default: () => [],
		},

		suggestedGoals: {
			type: Array,
			default: () => [],
		},

		context: {
			type: String,
		},
	},

	components: {
		Drawer,
		GoalListItem,
	},

	data() {
		return {
			showSuggestions: false,
			selectedSuggestions: new Map(),
			retrievingGoalData: false,
			showZillError: false,
		}
	},

	computed: {
		...mapStores(useOrganisationsStore),

		buttonLabelSuffix() {
			if(this.context === 'theme') {
				return 'thema';
			} else {
				return 'lesplan';
			}
		},
	},

	created() {
		this.showSuggestions = this.selectedGoals.length === 0 && this.suggestedGoals.length > 0;
	},

	async mounted() {

		if(!this.showSuggestions) {
			this.prepareZillSelector();
		}
	},

	beforeUnmount() {
		window.removeEventListener("message", this.handlePostMessage);
		window.removeEventListener("resize", this.adjustIframeHeight);
	},

	methods: {
		addGoal(goal) {
			// check if goal was already selected (just in case, normally not possible if Zill Selector got initialized correctly)
			if(this.selectedGoals.find(g => g.sourceId === goal.sourceId)) {
				return;
			}
			this.$emit('goalSelected', goal);
		},

		removeGoal(goal) {
			this.$emit('goalUnselected', goal);
		},

		selectSuggestedGoal(goal) {
			this.selectedSuggestions.set(goal.sourceId, goal);

			// also add the parent goal if any
			if(goal.sourceParentPath) {
				const parentGoal = this.suggestedGoals.find(g => g.fullPath === goal.sourceParentPath);
				if(parentGoal) {
					this.selectedSuggestions.set(parentGoal.sourceId, parentGoal);
				}
			}
		},

		deselectSuggestedGoal(goal) {
			this.selectedSuggestions.delete(goal.sourceId);

			// also remove selected subgoals if any
			const subgoals = this.suggestedGoals.filter(g => g.sourceParentPath === goal.fullPath);
			for(let subgoal of subgoals) {
				this.selectedSuggestions.delete(subgoal.sourceId);
			}
		},

		prepareZillSelector() {
			this.showSuggestions = false;
			this.commitSelectedSuggestedGoals();

			this.$nextTick().then(() => {
				window.addEventListener('message', this.handlePostMessage);
				window.addEventListener("resize", this.adjustIframeHeight);

				this.zillSelectorWindow = document.getElementById('zill-selector').contentWindow;
				this.adjustIframeHeight();
				this.loadZillSelector();
			});
		},

		handlePostMessage(event) {
			if (event.origin !== ZILL_SELECTOR_URL) return;
			// console.log('Zill selector message', event.data);
			if(event.data.status === 1) {
				if(event.data.event && event.data.event === 'loaded') {
					this.sendZillSelectorState();
				} else if(event.data.state) {
					this.handleSelectionUpdate(event.data.state.activity.selections);
				}
			}
		},

		loadZillSelector() {
			this.zillSelectorWindow.location = ZILL_SELECTOR_URL + '/#/?waitForState';
		},

		sendZillSelectorState() {
			if(this.zillSelectorWindow === null) return;

			// http://zill-developers.katholiekonderwijs.vlaanderen.s3-website-eu-west-1.amazonaws.com/content/implement/loading_state.html
			const state = {
				state: {
					"activity": {
						"pupilGroup": {
							"name": "",
						},
						"selections": this.selectionPermaLinks(),
					},
					"curriculum": {
						"version": "/content/0bb40237-487c-4c9c-9608-1e30debadb9f"
					},
					"returnButton": this.buttonLabelSuffix,
					"put": false,
				}
			};
			this.zillSelectorWindow.postMessage(state, ZILL_SELECTOR_URL);
		},

		adjustIframeHeight() {
			let container = document.getElementById("selector-container");
			let iframe = document.getElementById("zill-selector");
			iframe.style.height = container.clientHeight + "px";
		},

		selectionPermaLinks() {
			var permalinks = [];
			if(this.selectedSuggestions.size > 0) {
				permalinks = [...this.selectedSuggestions.keys()].map(goalId => '/content/' + goalId);
			}
			if(this.selectedGoals.length > 0) {
				permalinks = permalinks.concat([...this.selectedGoals].map(goal => '/content/' + goal.sourceId));
			}
			return permalinks;
		},

		async handleSelectionUpdate(newSelection: string[] = []) {
			this.retrievingGoalData = true;

			const goalsToRemove = new Map(this.selectedGoals.map(goal => [goal.sourceId, goal]));

			let zillLoader = new ZillLoader();
			try {
				await zillLoader.load(newSelection).then(goalCollections => {
					goalCollections.forEach(goals => {
						goals.forEach((goal) => { this.addGoal(goal); goalsToRemove.delete(goal.sourceId); });
					});
				});
			} catch(e) {
				console.error('Error loading goals from ZiLL API', e);
				this.retrievingGoalData = false;
				this.showZillError = true;
				return;
			}

			// remove goals that are no longer selected
			for(let goal of goalsToRemove.values()) {
				this.removeGoal(goal);
			}

			this.$refs['zill-drawer'].prepareToClose();
		},

		commitSelectedSuggestedGoals() {

			const selectedSuggestions = this.selectedSuggestions.values();
			for(let goal of selectedSuggestions) {
				this.addGoal(goal);
			}

		},

		closeSuggestionsPanel() {
			this.showSuggestions = false;
			this.commitSelectedSuggestedGoals();

			this.$refs['zill-drawer'].prepareToClose();
		},
	}

}
</script>
